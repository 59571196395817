<template>
    <div class="edu-frame">
        <edu-nav-bar title="报读须知" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div v-if="rules != null" class="content-frame">
            <div class="content-title">{{ rules.ruleTitle }}<!--广东省妇女儿童活动中心2024年春季招生简章--></div>
            <hr class="split-line"/>
            <div class="content-detail" v-html="rules.recruitRules"></div>
        </div>
        <div v-if="rules != null" class="bottom-frame">
            <van-checkbox v-model="readed" shape="square" style="margin-bottom: 8px" @change="changeReaded"><div style="font-weight: bold;margin-top: 5px;font-size: 14px;">我已仔细阅读并同意遵守</div> </van-checkbox>
            <div style="width:100%;margin: 0px auto;overflow: hidden;">
                <van-button :loading="loading" square block type="primary" @click="next" :disabled="btnText != '下一步'">{{btnText}}</van-button>
            </div>
        </div>


    </div>
</template>
<script>
import {Button, Checkbox} from 'vant';
import EduNavBar from "@/components/EduNavBar";
import BaomingApi from "@/api/BaomingApi";
import Tools from "@/api/Tools";
export default {
    components: {
        EduNavBar,
        VanButton: Button,
        VanCheckbox: Checkbox,

    },
    data() {
        return {
            readed: false,
            show: false,
            btnText: '下一步',
            calc: 5,
            timer: null,
            rules: null,
            loading: false
        }
    },
    methods: {
        changeReaded() {
            if (this.readed == false) {
                if (this.timer != null){this.calc = 5; clearInterval(this.timer);}
                this.btnText = '下一步'
                return
            } else {
                this.toDzfp()
            }
        },
        next() {
            if (this.loading) {
              return;
            }
            if (!this.readed) {
                this.$dialog.alert({ message: '请选中“我已仔细阅读并同意遵守”'});
                return
            }
            this.$router.push({ name: 'dzfp' }) // 电子发票
        },
        toDzfp() {
            // 报读倒计时
            this.btnText = this.calc + '后下一步'
            this.calc--
            this.timer = setInterval(()=>{
                console.log(this.btnText)
                if (this.calc <= 0) {
                    this.btnText = '下一步'
                    clearInterval(this.timer)
                    this.calc = 5
                } else {
                    this.btnText = this.calc + '后下一步'
                    this.calc--
                }
            }, 1000);
        }
    },
    mounted() {
        // TODO 获取报读须知
        var cust = Tools.getCurCust()
        cust.showError = false
        this.loading = true
        // console.log('cust', cust)
        BaomingApi.getRecruitRules(cust).then(response => {
            this.loading = false
            this.rules = response.res.rules
            // console.log(this.rules)
        }).catch(e => {
            this.loading = false
            this.$router.replace({ name: 'error', params: { msg: e.data.msg } })
        })
    },
    destroyed() {
        if (this.timer != null){clearInterval(this.timer)}
    }
}
</script>
<style scoped>
    .content-frame {
        width: calc(95% - 30px);
        margin: 8px auto;
        padding: 0 15px;
        height: calc(100% - 84px - 46px - 16px);
        background-color: white;
        color: black;
        position: relative;
        overflow: auto;
    }
    .split-line {
        width: 95%;
        margin: 0;
        padding: 0;
        height: 1px;
        background-color: #dddddd;
        border: 0;
    }
    .content-title {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;

    }
    .content-detail {
        font-size: 14px;
        margin: 15px 0;
    }
    .bottom-frame {
        width: 100%;
        height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: white;
    }

</style>
